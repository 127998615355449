import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

// Modules
import transportSituation from '@/modules/transport-situation/config/store'
import isochronesAnalysis from '@/modules/isochrone-analysis/config/store'
import publicTransport from '@/modules/monitoring-pt/config/store'
import digitalTwin from '@/modules/digital-twin/config/store'
import itsControl from '@/modules/its-control/config/store'
import roadWorks from '@/modules/road-works/config/store'
import emergency from '@/modules/emergency/config/store'
import modeling from '@/modules/modeling/config/store'
import licenses from '@/modules/licenses/config/store'
import scenario from '@/modules/scenario/config/store'
import parking from '@/modules/parking/config/store'
import tsodd from '@/modules/tsodd/config/store'
import ksodd from '@/modules/ksodd/config/store'
import asudd from '@/modules/asudd/config/store'
import user from '@/modules/user/config/store'
import mrir from '@/modules/mrir/config/store'
import info from '@/modules/info/config/store'
import gis from '@/modules/gis/config/store'
import pdd from '@/modules/pdd/config/store'

// import roadWorks2 from '@/modules/road-works-2/config/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    gis,
    pdd,
    mrir,
    user,
    info,
    ksodd,
    asudd,
    tsodd,
    parking,
    licenses,
    modeling,
    scenario,
    roadWorks,
    emergency,
    itsControl,
    digitalTwin,
    publicTransport,
    isochronesAnalysis,
    transportSituation
  }
})
