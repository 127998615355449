import { getCookie, setCookie } from 'HELPERS'

export const toggleBodyTheme = newTheme => {
  if (newTheme) {
    setCookie('theme', newTheme)
  } else {
    newTheme = getCookie('theme') || 'dark'
  }

  const oldClass = newTheme === 'dark' ? 'light' : 'dark'

  document.body.classList.remove(oldClass)
  document.body.classList.add(newTheme)
}
