// Main
import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import i18n from './extensions/i18n'

// Libs
import PortalVue from 'portal-vue'

// Styles
import './assets/styles/main.scss'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import SvgSprite from 'vue-svg-sprite'

// Ritm-UI
import '@/r-ui'
import loader from './extensions/loader'
import skeleton from './extensions/skeleton'
import badge from './extensions/badger'
import disabler from './extensions/disabler'
import Notification from '@/extensions/notification'
import Dialog from '@/extensions/dialog'

// Date formatter
import RitmDate from 'ritm-date'

// Other
import { URL } from '@/config/constants'
import request from 'API'

// WS
import ws from '@/extensions/ws'

const mixin = {
  data() {
    return { ...request, URL }
  },
  methods: {
    $rDate(date) {
      return new RitmDate(date).setLocale('ru')
    }
  }
}

Vue.mixin(mixin)

Vue.directive('loading', loader)
Vue.directive('skeleton', skeleton)
Vue.directive('badge', badge)
Vue.directive('disabled', disabler)
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    var vm = vnode.context
    var callback = binding.value

    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        return callback.call(vm, event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
Vue.use(ws)

Vue.use(PortalVue)

Vue.use(Notification)
Vue.use(Dialog)

Vue.use(SvgSprite, {
  url: '/sprite/symbol/main.svg',
  class: ''
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
