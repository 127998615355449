import vue from 'vue'

export default {
  state: {
    map: {},
    fields: {
      parkings: null,
      parkomats: null,
      terminals: null,
      taxi: null,
      tariffs: null
    },
    city: null,
    heatmap: {
      active: false,
      layer: null,
      attribute: null
    },
    additional_filters: {
      parkomats: null,
      terminals: null,
      taxi: null
    },
    // parkingMeters: false,
    // parkingTerminals: false,
    // taxi: false,
    objectCard: null,
    objectEditor: null,
    tariff: null,
    tariffTypes: null,
    filters: null,
    layerNames: {},
    layerStyles: {},
    activeLayers: []
  },
  getters: {
    getParkingMapConfig: state => state.map,
    getParkingFields: state => key => state.fields[key],
    getParkingSelectedCity: state => state.city,
    getParkingFiltersById: state => id => state.layerStyles[id]?.filters || [],
    getParkingFreeTypes: state => state.tariffTypes?.free || []
  },
  mutations: {
    PARKING_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        vue.set(state, `${field}`, value)
      }
    }
  }
}
