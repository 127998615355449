import turfBbox from '@turf/bbox'
import {
  clusterLayerConfig,
  clusterCountLayerConfig,
  styleConfigs
} from '@/config/map/layer-styles'
import { palettes } from '@/config/map/colors'
import { paintSettings, layoutSettings } from '@/config/map/layer-setting-names'
import { filterObjectByKeys } from './data-processing'
//
const DIVIDED_PI = Math.PI / 180
const R = 6371e3 // Earth's radius in meters
//

export const getFirstSymbolId = mapgl => {
  const { layers } = mapgl.getStyle()

  const symbolIndex = layers.findIndex(
    e => e.type === 'symbol' && e.id.includes('label')
  )

  if (symbolIndex !== -1) {
    return layers[symbolIndex].id
  }

  return layers[layers.length].id
}

export const getFirstCustomLayerId = mapgl => {
  const { layers } = mapgl.getStyle()

  const customLayerFirstIndex = layers.findIndex(l => !l.metadata) + 1
  return layers[customLayerFirstIndex]?.id
}

export const getFirstIdAfterLayersGroup = (mapgl, id) => {
  const allMapLayers = mapgl.getStyle().layers
  const currentLayersGroup = allMapLayers.filter(
    l =>
      l.id === id || (l.id.includes(`${id}_`) && l.id.indexOf(`${id}_`) === 0)
  )
  const last = currentLayersGroup[currentLayersGroup.length - 1].id
  const index = allMapLayers.findIndex(l => l.id === last)
  return allMapLayers[index + 1]?.id
}

export const loadCustomIcons = (mapgl, array) => {
  const MAP_ICON_SIZE = 64

  return array.map(item => {
    return new Promise(resolve => {
      const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
      image.crossOrigin = 'Anonymous'
      image.style.backgroundPosition = '50%, 50%'
      image.style.backgroundSize = '100%'
      image.addEventListener('load', () => {
        if (!mapgl.hasImage(item.name)) {
          mapgl.addImage(item.name, image, { sdf: item.sdf })
        }
        resolve()
      })

      image.src = item.url
    })
  })
}

export const flyToGeom = ({
  mapgl,
  geom,
  bbox,
  coords,
  speed = 5,
  padding = 128,
  zoom: customZoom = 17,
  ...params
}) => {
  let center, zoom

  if (geom || bbox) {
    const box = bbox ?? turfBbox(geom)

    const bounds = mapgl.cameraForBounds(box, {
      padding,
      maxZoom: 17
    })
    center = bounds.center
    zoom = bounds.zoom
  } else {
    center = coords
    zoom = customZoom
  }
  mapgl.flyTo({
    center,
    zoom,
    speed,
    ...params
  })
}

export const getPolygonCoordsArrayByGeom = geom => {
  const isMultiGeom = geom?.type.toLowerCase().includes('multi')
  return isMultiGeom ? geom?.coordinates[0][0] : geom?.coordinates[0]
}

export const geojsonByGeom = geometry => {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry
      }
    ]
  }
}

export const featuresToGeojson = features => {
  return {
    type: 'FeatureCollection',
    features
  }
}

export const emptyGeojson = {
  type: 'FeatureCollection',
  features: []
}

export const getColorPropByGeomType = geomType => {
  const type = geomType?.toLowerCase()

  switch (type) {
    case 'polygon':
    case 'multipolygon':
    case 'fill':
      return 'fill-color'
    case 'linestring':
    case 'line':
    case 'multilinestring':
      return 'line-color'
    case 'point':
    case 'circle':
      return 'circle-color'
    default:
      console.warn('wrong geom type')
      return 'circle-color'
  }
}

export const getOpacityPropByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'polygon':
    case 'multipolygon':
    case 'fill':
      return 'fill-opacity'
    case 'linestring':
    case 'line':
    case 'multilinestring':
      return 'line-opacity'
    case 'point':
    case 'circle':
      return 'circle-opacity'
    default:
      console.warn('wrong geom type')
      return 'circle-opacity'
  }
}

export const getStrokeColorPropByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'fill':
      return 'line-color'
    case 'linestring':
    case 'line':
    case 'multilinestring':
      return null
    case 'point':
    case 'circle':
      return 'circle-stroke-color'
    default:
      console.warn('wrong geom type')
      return 'circle-stroke-color'
  }
}

export const getSizePropByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'linestring':
    case 'line':
    case 'multilinestring':
      return 'line-width'
    case 'point':
    case 'circle':
      return 'circle-radius'
    default:
      console.warn('wrong geom type')
      return 'circle-radius'
  }
}

export const getStrokeOpacityPropByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'fill':
      return 'line-opacity'
    case 'linestring':
    case 'line':
    case 'multilinestring':
      return null
    default:
      return 'circle-stroke-opacity'
  }
}
export const getStrokeWidthPropByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'fill':
      return 'line-width'
    case 'linestring':
    case 'line':
    case 'multilinestring':
      return null
    default:
      return 'circle-stroke-width'
  }
}

export const getIconByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'point':
    case 'multipoint':
      return 'node-layer'
    case 'polygon':
    case 'multipolygon':
      return 'polygon-layer'
    case 'linestring':
    case 'multilinestring':
      return 'line-layer'
    default:
      return 'common-layer'
  }
}

export const getLayerTypeByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'point':
    case 'multipoint':
    case 'circle':
      return 'circle'
    case 'polygon':
    case 'multipolygon':
    case 'fill':
      return 'fill'
    case 'linestring':
    case 'line_string':
    case 'multilinestring':
    case 'line':
      return 'line'
    default: {
      console.warn(`type ${geomType} not found`)
      return 'circle'
    }
  }
}

export const getAdditionalLayers = id => {
  return [
    `${id}_fill_extrusion`,
    `${id}_heatmap`,
    `${id}_hexagon`,
    `${id}_centroid`,
    `${id}_clusters`,
    `${id}_clusters_count`,
    `${id}_arrows`,
    `${id}_icons`,
    `${id}_stroke`,
    `${id}_epure`,
    `${id}_epure_extrusion`
  ]
}

export const createExtrusionRangeValue = (
  field,
  fieldMin,
  fieldMax,
  height
) => {
  if (fieldMin === fieldMax) {
    return height
  } else {
    return [
      'interpolate',
      ['linear'],
      ['get', field],
      fieldMin,
      0,
      fieldMax,
      height
    ]
  }
}

export const objectToFeature = (object, geomField = 'geom') => {
  const coordinates = object[geomField]?.coordinates
  const type = object[geomField]?.type || null
  const properties = { ...object }
  delete properties[geomField]
  const geometry =
    coordinates && type
      ? {
          coordinates,
          type
        }
      : null

  return {
    type: 'Feature',
    properties,
    geometry
  }
}

export const createFeature = (type, coordinates, properties = {}) => {
  const geometry = coordinates
    ? {
        coordinates,
        type
      }
    : null

  return {
    type: 'Feature',
    properties,
    geometry
  }
}

export const pointFeature = (coordinates, properties = {}) => {
  return createFeature('Point', coordinates, properties)
}

export const lineFeature = (coordinates, properties = {}) => {
  return createFeature('LineString', coordinates, properties)
}

export const multiLineFeature = (coordinates, properties = {}) => {
  return createFeature('MultiLineString', coordinates, properties)
}

export const polygonFeature = (coordinates, properties = {}) => {
  return createFeature('Polygon', coordinates, properties)
}

export const getUniqueFeatures = (features, uniquePropName = 'id') => {
  const uniqueIds = new Set()
  const uniqueFeatures = []
  for (const feature of features) {
    const id = feature.properties?.[uniquePropName]
    if (!uniqueIds.has(id)) {
      uniqueIds.add(id)
      uniqueFeatures.push(feature)
    }
  }
  return uniqueFeatures
}

export const createClusterLayer = (mapgl, id, clustersHandlers) => {
  mapgl.addLayer({
    id: `${id}_clusters`,
    source: id,
    ...clusterLayerConfig
  })

  mapgl.addLayer({
    id: `${id}_clusters_count`,
    source: id,
    ...clusterCountLayerConfig
  })

  clustersHandlers[id] = e => {
    const features = mapgl.queryRenderedFeatures(e.point, {
      layers: [`${id}_clusters`]
    })
    const clusterId = features[0].properties.cluster_id

    mapgl.getSource(id).getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (!err) {
        mapgl.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom + 2
        })
      }
    })
  }

  mapgl.on('click', `${id}_clusters`, clustersHandlers[id])
}

export const getDistanceBetweenCoords = (c1, c2, options = {}) => {
  const units = options?.units || 'kilometers'
  const [lon1, lat1] = c1
  const [lon2, lat2] = c2

  const phi1 = lat1 * DIVIDED_PI
  const phi2 = lat2 * DIVIDED_PI
  const deltaPhi = (lat2 - lat1) * DIVIDED_PI
  const deltaLambda = (lon2 - lon1) * DIVIDED_PI

  const a =
    Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
    Math.cos(phi1) *
      Math.cos(phi2) *
      Math.sin(deltaLambda / 2) *
      Math.sin(deltaLambda / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  switch (units) {
    case 'meters':
      return R * c
    default:
      return (R * c) / 1000
  }
}

export const getLengthOfLine = (line, options = {}) => {
  const { units } = options
  const lineCoords = line.coordinates ?? line.geometry.coordinates
  let length = 0

  // old version
  // for (let i = 1; i < lineCoords.length; i++) {
  //   const [lon1, lat1] = lineCoords[i - 1]
  //   const [lon2, lat2] = lineCoords[i]
  //
  //   const phi1 = lat1 * DIVIDED_PI
  //   const phi2 = lat2 * DIVIDED_PI
  //   const deltaPhi = (lat2 - lat1) * DIVIDED_PI
  //   const deltaLambda = (lon2 - lon1) * DIVIDED_PI
  //
  //   const a =
  //       Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
  //       Math.cos(phi1) *
  //       Math.cos(phi2) *
  //       Math.sin(deltaLambda / 2) *
  //       Math.sin(deltaLambda / 2)
  //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  //
  //   length += R * c
  // }

  if (line.geometry.type.toLowerCase().includes('multi')) {
    lineCoords.forEach(item => {
      length += calculateDistance(item)
    })
  } else {
    length = calculateDistance(lineCoords)
  }

  switch (units) {
    case 'kilometers':
      return Math.round(length / 1000)
    default:
      return Math.round(length)
  }
}

const calculateDistance = coords => {
  let length = 0
  for (let i = 1; i < coords.length; i++) {
    const [lon1, lat1] = coords[i - 1]
    const [lon2, lat2] = coords[i]

    const phi1 = lat1 * DIVIDED_PI
    const phi2 = lat2 * DIVIDED_PI
    const deltaPhi = (lat2 - lat1) * DIVIDED_PI
    const deltaLambda = (lon2 - lon1) * DIVIDED_PI

    const a =
      Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(phi1) *
        Math.cos(phi2) *
        Math.sin(deltaLambda / 2) *
        Math.sin(deltaLambda / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    length += R * c
  }
  return length
}

export const prepareHeatmapColor = paletteId => {
  const palette = [
    'rgba(0,0,0,0)',
    ...palettes.find(p => p.id === paletteId).value
  ]

  return [
    'interpolate',
    ['linear'],
    ['heatmap-density'],
    ...palette.reduce((a, c, i) => {
      a.push(Number((i / palette.length).toFixed(2)))
      a.push(c)

      return a
    }, [])
  ]
}

export const defaultHeatmapOptions = ({ attribute, min, max }) => {
  const defaultConfig = styleConfigs.circle.heatmap

  let weight

  if (!isNaN(min) && !isNaN(max) && attribute) {
    weight = ['interpolate', ['linear'], ['get', attribute], 0, min, max, 10]
  }

  return {
    type: 'heatmap',
    paint: {
      'heatmap-weight': weight || 1,
      'heatmap-intensity': {
        stops: [
          [1, 0],
          [22, defaultConfig.intensity / 100]
        ]
      },
      'heatmap-radius': {
        stops: [
          [1, 1],
          [22, defaultConfig.radius * 2]
        ]
      },
      'heatmap-color': prepareHeatmapColor(1),
      'heatmap-opacity': 0.8
    }
  }
}

export const getFilterAttr = filter => {
  if (!filter?.length) return null

  // if (op === 'in') {
  //   filter = [
  //     op,
  //     [conversion, value.toLowerCase()],
  //     [conversion, ['downcase', ['get', attribute.value]]]
  //   ]
  // } else {
  //   filter = [op, [conversion, ['get', attribute.value]], [conversion, value]]
  // }

  // datetime
  // filter = [
  //   'all',
  //   ['>=', ['get', attribute.value], start],
  //   ['<=', ['get', attribute.value], end]
  // ]

  // array
  // const conditions = value.map(id => {
  //   return [op, id, ['get', attribute.value]]
  // })
  // filter = ['any', ...conditions]

  const op = filter[0]
  let field
  let value

  if (op === 'in') {
    value = filter[1][1]
    field = filter[2][1][1][1]
  } else if (op === 'all') {
    // for datetime
    value = [filter[1][2], filter[2][2]]
    field = filter[1][1][1]
  } else if (op === 'any') {
    // for multiple select
    value = filter.reduce((acc, curr, index) => {
      if (index > 0) acc.push(curr[1])
      return acc
    }, [])
    field = filter[1][2][1]
  } else {
    value = filter[2][1] || 'no_data'
    field = filter[1][1][1]
  }

  return {
    op,
    field,
    value
  }
}

export const validateLayerConfigByType = (config, type) => {
  const paint = filterObjectByKeys(
    config.paint || {},
    paintSettings[type],
    true
  )

  const layout = filterObjectByKeys(
    config.layout || {},
    layoutSettings[type],
    true
  )

  return { paint, layout }
}
