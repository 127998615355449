export default {
  state: {
    roles: null,
    activeModule: 'users',
    endpoints: null,
    modules: null,
    moduleStatuses: null,
    endpointsStatuses: null,
    checkedUserId: null,
    checkedRoleId: null
  },
  getters: {
    getModuleAvailability: state => moduleName =>
      !!state.modules?.find(m => m.name === moduleName)?.available
  },
  mutations: {
    MRIR_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')

        state[subField[0]][subField[1]] = value
      } else {
        state[field] = value
      }
    }
  }
}
