<template>
  <div>
    <portal to="main-portal">
      <r-button
        v-if="aLotOfNotifications"
        class="notifications__dismiss-button"
        type="warning"
        icon="close-delete"
        @click="dismissAll"
      >
        {{ $t('dismiss-all-notifications') }}
      </r-button>
      <transition-group
        class="notifications"
        :class="{ offset: aLotOfNotifications }"
        name="list-complete"
        tag="div"
      >
        <notification
          v-for="item in list"
          :id="item.id"
          :key="item.id"
          class="list-complete-item"
          :message="item.message"
          :title="item.title"
          :icon="item.icon"
          :link="item.link"
          :duration="item.duration"
          :type="item.type"
          @delete="handleDelete"
        />
      </transition-group>
    </portal>
  </div>
</template>

<script>
import { getCookie } from 'HELPERS'

export default {
  components: {
    Notification: () => import('./notification')
  },
  data() {
    return {
      list: [],
      parent: null
    }
  },
  computed: {
    aLotOfNotifications() {
      return this.list.length > 3
    }
  },
  created() {
    this.$root.$i18n.locale = getCookie('locale')
  },
  methods: {
    dismissAll() {
      this.list = []
    },
    addNotification({ type, title, message, duration, icon, link }) {
      this.list.unshift({
        title,
        message: message || '',
        icon: icon || '',
        duration,
        type,
        link,
        id: (+new Date()).toString(16)
      })
    },
    handleDelete(id) {
      this.list = this.list.filter(item => item.id !== id)
    }
  }
}
</script>

<style lang="scss">
.notifications {
  position: fixed;
  top: 3.5rem;
  right: 0.5rem;
  z-index: 4000;
  display: grid;
  grid-gap: 1rem;
  overflow-y: auto;
  justify-content: end;
  overflow-x: hidden;
  width: 330px;
  align-items: start;
  max-height: calc(100vh - 4rem);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.offset {
    top: 6.5rem;
  }

  &__dismiss-button {
    position: fixed;
    top: 3.5rem;
    right: 0.5rem;
    z-index: 3000;
  }
}

.list-complete-item {
  transition: all 0.33s;
}

.list-complete-item {
  transition: all 0.33s ease;
}

.list-complete-enter-active {
  transform: translateX(0);
  opacity: 1;
}

.list-complete-enter {
  transform: translateX(100%);
  opacity: 0;
}

.list-complete-enter-to {
  animation: enter 0.33s ease;
  opacity: 1;
}

.list-complete-leave-to {
  opacity: 0;
}
.list-complete-leave {
  opacity: 0;
}

.list-complete-leave-active {
  animation: leave 0.33s ease;
  opacity: 0.33;
}

@keyframes leave {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(110%);
  }
}

@keyframes enter {
  0% {
    transform: translateX(100%);
  }
  80% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0%);
  }
}
</style>

<i18n>
{
  "ru": {
    "dismiss-all-notifications": "Скрыть всё"
  },
  "en": {
    "dismiss-all-notifications": "Dismiss all"
  }
}
</i18n>
