import vue from 'vue'

export default {
  state: {
    tree: [],
    map: {},
    layerStyles: {},
    activeLayers: [],
    fields: {},
    isSelectDisabled: false,
    zIndex: {},
    layerNames: {}
  },
  getters: {
    getGisMapConfig: state => state.map,
    getGisActiveLayers: state => state.activeLayers,
    getGisFiltersById: state => id => state.layerStyles[id]?.filters || [],
    getGisLayerStyles: state => state.layerStyles,
    getGisLayersZIndex: state => state.zIndex
  },
  mutations: {
    GIS_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        vue.set(state, `${field}`, value)
      }
    },
    GIS_MAP_SET(state, [field, value]) {
      vue.set(state.map, field, value)
    },
    GIS_SET_FILTER_PROP(state, { filterId, action, layerId, prop, value }) {
      const { filters } = state.layerStyles[layerId]
      const index = filters.findIndex(({ id }) => id === filterId)
      if (action === 'delete') {
        filters.splice(index, 1)
      } else if (!filters[index]) {
        console.warn('filter not found!')
        return
      } else if (prop && value !== undefined) {
        filters[index][prop] = value
      }
    },
    GIS_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    GIS_SPLICE(state, [field, value]) {
      const index = state[field].indexOf(value)
      if (index >= 0) state[field].splice(index, 1)
    }
  }
}
