import vue from 'vue'

export default {
  state: {
    map: {},
    fields: {},
    isSelectDisabled: false,
    layerNames: {},
    layerTypes: {},
    layerStyles: {},
    activeLayers: [],
    selectedModelId: null,
    fullDataSet: {},
    // pt
    ptLayerNames: {},
    activeTsysCode: null,
    activeLineRouteItems: [],
    activeROEditingId: null
    // ptLayerTypes: {}
    // ptActiveLayers: []
  },
  getters: {
    getDigitalTwinMapConfig: state => state.map,
    getDigitalTwinActiveLayers: state => state.activeLayers,
    getDigitalTwinLayerStyles: state => state.layerStyles,
    getDigitalTwinFiltersById: state => id =>
      state.layerStyles[id]?.filters || []
  },
  mutations: {
    DIGITAL_TWIN_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        vue.set(state, `${field}`, value)
      }
    },
    DIGITAL_TWIN_ASSIGN(state, { field, key, value }) {
      state[field] = Object.assign({ ...state[field] }, { [key]: value })
    },
    DIGITAL_TWIN_SET_FILTER_PROP(state, pr) {
      const { filterId, action, layerId, prop, value } = pr

      const { filters } = state.layerStyles[layerId]

      const index = filters.findIndex(({ id }) => id === filterId)

      if (action === 'delete') {
        filters.splice(index, 1)
      } else if (!filters[index]) {
        console.warn('filter not found!')
      } else if (prop && value !== undefined) {
        filters[index][prop] = value
      }
    }
  }
}
