import { DITS, INFO_TABLES, ZPIS } from '../config/consts'

export default {
  state: {
    city: null,
    map: {},
    objectCard: null,
    editorCard: null,
    objectNavHistory: [],
    loadingTreeNodeId: null,
    layerNames: {},
    tables: {},
    filters: {
      [INFO_TABLES]: [],
      [DITS]: [],
      [ZPIS]: []
    },
    fields: {},
    hasObjectChanges: false
  },
  getters: {
    getInfoCity: state => state.city,
    getInfoFields: state => key => state.fields[key]
  },
  mutations: {
    INFO_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')
        state[subField[0]][subField[1]] = value
      } else {
        state[field] = value
      }
    },
    INFO_SET_NAV_HISTORY(state, value) {
      if (value === -1) {
        state.objectNavHistory.pop()

        return
      }

      state.objectNavHistory.push(value)

      if (value === null) {
        state.objectNavHistory.length = 0
      }
    }
  }
}
