// Module alias
export const INFO_MODULE = 'info'

// Main alias url
export const ROOT_ENDPOINT = 'mciud'

// Data types
export const INFO_TABLES = 'info_tables'
export const DITS = 'dits'
export const ZPIS = 'zpis'

// Base layers
export const SOURCE = 'info'
export const LAYER_ICON = `${SOURCE}_icon`
export const LAYER_CIRCLE = `${SOURCE}_circle`

// Clusters
export const LAYER_CLUSTERS = `${SOURCE}_clusters`

// Fake layer
export const SOURCE_FAKE = `${SOURCE}_fake`
export const LAYER_FAKE = `${SOURCE}_fake`

// Create new object
export const CREATE_NEW_OBJECT = 'create_new_object'

// Editor card tabs
export const TAB_INFO = 'info'
export const TAB_SCENARIOS = 'scenarios'

// Supported files for upload content
export const SUPPORTED_IMAGE_FILE_TYPES = [
  '.jpg',
  '.jpeg',
  '.png',
  '.webp',
  '.gif',
  '.bmp'
]

export const SUPPORTED_VIDEO_FILE_TYPES = ['.mp4', '.mpeg', '.ogg', '.webm']

export const SUPPORTED_FILE_TYPES = [
  ...SUPPORTED_IMAGE_FILE_TYPES,
  ...SUPPORTED_VIDEO_FILE_TYPES
]
