var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"r-ritm-tabs",class:{ indent: _vm.indent, mini: _vm.mini, disabled: _vm.disabled }},[_c('ul',{staticClass:"r-ritm-tabs__list",class:{
      mini: _vm.mini,
      metro: _vm.metro,
      simple: _vm.simple,
      vertical: _vm.vertical,
      nowrap: _vm.nowrap,
      'even-width': _vm.evenWidth
    }},_vm._l((_setup.tabs),function(tab){return _c('li',{key:tab.componentOptions.propsData.id,staticClass:"list-item__header",class:[
        tab.componentOptions.propsData.type,
        {
          active: tab.componentOptions.propsData.id === _setup.currentTab,
          metro: _vm.metro
        }
      ],on:{"click":function($event){return _setup.setActive(tab.componentOptions.propsData.id, { isManual: true })}}},[(tab.componentOptions.propsData.icon)?_c('r-icon',{attrs:{"name":tab.componentOptions.propsData.icon,"size":18}}):_vm._e(),(tab.componentOptions.propsData.tabName)?_c('span',{staticClass:"list-item__header-name"},[_vm._v(" "+_vm._s(tab.componentOptions.propsData.tabName)+" ")]):_vm._e()],1)}),0),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }