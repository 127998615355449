const getBadgeColor = type => {
  return `var(--accent-${type || 'primary'})`
}

export default {
  inserted: function (el, binding) {
    el.parentNode.style.cssText += ';position: relative !important;'
    const badge = document.createElement('span')

    badge.classList.add('v-badge')
    badge.style.width = '8px'
    badge.style.height = '8px'
    badge.style.borderRadius = '50%'
    badge.style.position = 'absolute'
    badge.style.top = binding.value.button ? '4px' : '0px'
    badge.style.right = binding.value.button ? '4px' : '-8px'
    badge.style.zIndex = '1'
    badge.style.backgroundColor = getBadgeColor(binding.value.color)

    if (binding.value.title) {
      badge.title = binding.value.title
      badge.style.cursor = 'pointer'
    }

    el.parentNode.insertBefore(badge, el.nextSibling)
    el._parent = badge.parentNode

    if (binding.value.show) {
      // el.parentNode.style.cssText += ';position: relative !important;'
      // const badge = document.createElement('span')

      // badge.classList.add('v-badge')
      // badge.style.width = '8px'
      // badge.style.height = '8px'
      // badge.style.borderRadius = '50%'
      // badge.style.position = 'absolute'
      // badge.style.top = binding.value.button ? '4px' : '0px'
      // badge.style.right = binding.value.button ? '4px' : '-8px'
      // badge.style.zIndex = '1'

      // if (binding.value.show === false) {
      //   badge.style.display = 'none'
      //   el.parentNode.style.cssText = el.style.cssText.replace(
      //     'position: relative !important;',
      //     ''
      //   )
      //   return
      // }

      badge.style.display = 'block'

      // if (binding.value.show) {
      // badge.style.backgroundColor = getBadgeColor(binding.value.color)
      // }

      // el.parentNode.insertBefore(badge, el.nextSibling)
      // el._parent = badge.parentNode
    } else {
      badge.style.display = 'none'
    }
  },
  update: function (el, binding) {
    const badge = el?.parentNode?.querySelector('.v-badge')

    if (badge && binding.value.show === false) {
      badge.style.display = 'none'
    } else if (badge) {
      badge.style.backgroundColor = getBadgeColor(binding.value.color)
      badge.style.display = 'block'
    }
  },
  unbind: function (el) {
    const badge = el?._parent?.querySelector('.v-badge')
    if (badge) el._parent.removeChild(badge)
  }
}
