const DEV_MODE = process.env.NODE_ENV === 'development'
const ENV_HOST = process.env.VUE_APP_HOST
const { protocol, origin } = window.location

const URLS = {
  test: 'test.ritm3.ru',
  demo: 'demo.ritm3.ru',
  asudd: 'asudd.ritm3.ru',
  new: 'new.ritm3.ru',
  test2: 'test2.ritm3.ru',
  test10: '10.10.10.24',
  eputsiso: 'eputs-so.ritm3.ru',
  surgut: 'surgut.ritm3.ru',
  prod: '/'
}

const WS_PROTOCOLS = {
  'http:': 'ws:',
  'https:': 'wss:'
}

//  ----- * ----- * ----- * ----- * ----- * ----- * ----- * ----- * -----  //

let HOST
if (!DEV_MODE) {
  HOST = URLS.prod
} else {
  // Toggle URL below ↓

  // HOST = URLS.eputsiso
  // HOST = URLS.asudd
  // HOST = URLS.surgut
  HOST = URLS.test2
}

//  ----- * ----- * ----- * ----- * ----- * ----- * ----- * ----- * -----  //

// const DEV_PROTOCOL = 'http://'
const DEV_PROTOCOL = 'https://'

const URL = DEV_MODE ? `${DEV_PROTOCOL}${HOST}` : URLS.prod
const ABS_URL = DEV_MODE ? `${DEV_PROTOCOL}${HOST}` : `${protocol}//${ENV_HOST}`

//  ----- * ----- * ----- * ----- * ws * ----- * ----- * ----- * -----  //

const WSP = DEV_MODE ? WS_PROTOCOLS['https:'] : WS_PROTOCOLS[protocol]
const WSH = DEV_MODE ? HOST : ENV_HOST

const WS_URL = `${WSP}//${WSH}/ws`
const DEFAULT_WS_URL = `${WSP}//${WSH}`
const NOTIFY_WS_URL = `${DEFAULT_WS_URL}/task_notification/ws`

//  ----- * ----- * ----- * ----- * ----- * ----- * ----- * ----- * -----  //
const MAIN_TITLE = 'RITM³'
export { NOTIFY_WS_URL, WS_URL, URL, MAIN_TITLE, ABS_URL, DEFAULT_WS_URL }
