<template>
  <div
    ref="group"
    class="r-radio-group"
  >
    <r-text
      v-if="title"
      type="caption"
    >
      {{ title }}
    </r-text>
    <r-radio
      v-for="radio in radios"
      :id="radio.componentOptions.propsData.id"
      :key="radio.componentOptions.propsData.id"
      :checked="radio.componentOptions.propsData.id === value"
      @change="$emit('change', radio.componentOptions.propsData.id)"
    >
      {{ radio.componentInstance.text }}
    </r-radio>
    <!-- FIXME: ???-->
    <div v-show="false">
      <slot />
    </div>
    <!-- FIXME: ???-->
  </div>
</template>

<script>
export default {
  name: 'RRadioGroup',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      radios: []
    }
  },
  mounted() {
    this.radios = this.$slots.default

    this.observer = new MutationObserver(
      function () {
        this.radios = this.$slots.default
      }.bind(this)
    )

    this.observer.observe(this.$refs.group, {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true
    })
  }
}
</script>
