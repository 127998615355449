import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from 'STORE'
import { MAIN_TITLE } from '@/config/constants'
import { getCookie } from 'HELPERS'
import i18n from '@/extensions/i18n'

import explorer from './explorer'
import planning from './planning'
import monitoring from './monitoring'
import management from './management'
import forecast from './forecast'
import system from './system'

Vue.use(VueRouter)

const wrapper = () => import('@/views/wrapper/wrapper')

const beforeEnter = (to, from, next) => {
  document.title = `${MAIN_TITLE} ${
    to.meta?.title
      ? `| ${i18n.t(to.meta.title)}`
      : to.meta?.subtitle
      ? `| ${i18n.t(to.meta.subtitle)}`
      : ''
  }`

  if (!getCookie('ritmToken') && to.name !== 'login') {
    next({
      name: 'login'
    })
  } else if (from.name !== to.name) {
    next()

    if (to.name !== 'login') {
      $store.commit('SYSTEM_SET', ['defaultPath', to.path])
    }
  }
}

const routes = [
  {
    path: '/app',
    name: 'app',
    component: () => import('@/views/app/app'),
    beforeEnter,
    redirect: 'app/explorer',
    children: [
      explorer(beforeEnter),
      planning(beforeEnter, wrapper),
      forecast(beforeEnter, wrapper),
      monitoring(beforeEnter, wrapper),
      management(beforeEnter, wrapper),
      system(beforeEnter, wrapper)
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/modules/user/auth/login'),
    beforeEnter,
    meta: {
      title: 'Авторизация'
    }
  },
  { name: 'all-routes', path: '*', redirect: $store.getters.getDefaultPath }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Hack
const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

export default router
