import Vue from 'vue'
import { getCookie, deleteCookie } from 'HELPERS'
const DEV_MODE = process.env?.NODE_ENV === 'development'

export default {
  state: {
    token: getCookie('ritmToken'),
    user: null,
    profile: null,
    permissions: {
      modules: null
    }
  },
  getters: {
    getToken: state => state.token,
    getProfile: state => state.profile,
    getCurrentUser: state => state.user,
    getModules: state => state.permissions.modules,
    hasUserAccessToModule: state => module_name => {
      return (
        !!state.permissions.modules?.get(module_name)?.available || DEV_MODE
      )
    }
  },
  mutations: {
    USER_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')
        Vue.set(state[subField[0]], subField[1], value)
      } else {
        Vue.set(state, field, value)
        state[field] = value
      }
    },
    CLEAR_USER_DATA(state) {
      state.datasources = {}
      state.user_config = {
        datasources: {},
        objectInfoFields: {},
        baselayerId: 5
      }

      state.token = undefined
      state.user = undefined
      deleteCookie('ritmToken')
    }
  }
}
