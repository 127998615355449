import vue from 'vue'

export default {
  state: {
    map: {},

    heatmap: {
      active: false,
      layer: null,
      attribute: null
    },
    fields: {
      taxi: null
    },
    additional_filters: {
      taxi: null
    },

    activeLink: null
  },
  getters: {},
  mutations: {
    TRANSPORT_SITUATION_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        vue.set(state, `${field}`, value)
      }
    }
  }
}
