import vue from 'vue'

export default {
  state: {
    map: {},
    fields: {
      events: null,
      applications: null,
      cameras: null,
      info_tables: null,
      dits: null,
      meteostations: null
    },
    city: null,
    tables: {},
    filters: {
      cameras: []
    },
    additionalLayers: {
      heatmap: false,
      info_tables: false,
      dits: false,
      meteostations: false
    },
    objectCard: null,
    cameraCardId: null,
    meteostation: false,
    meteostationId: null,
    services: {
      eventTypes: null,
      executors: null
    },
    statuses: {
      cameras: null,
      events: null,
      applications: null
    },
    layerNames: {},
    heatmap: {
      active: false,
      layer: null,
      attribute: null
    },
    additional_filters: {
      events: null,
      applications: null,
      cameras: null,
      info_tables: null,
      dits: null,
      meteostations: null
    }
  },
  getters: {
    getEmergencyMapConfig: state => state.map,
    getEmergencySelectedCity: state => state.city,
    getEmergencyFields: state => key => state.fields[key],
    getEmergencyStatusSlugById: state => (id, type) =>
      state.statuses?.[type].find(status => status.id === id)?.slug || 'none',
    getEmergencyEventType: state => id =>
      state.services.eventTypes.find(type => type.id === id)
  },
  mutations: {
    EMERGENCY_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        vue.set(state, `${field}`, value)
      }
    }
  }
}
