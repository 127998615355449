<template>
  <portal to="main-portal">
    <transition name="fade">
      <div
        v-if="visible"
        id="overlay"
        class="dialog__overlay"
        @click="overlayHandler"
      >
        <transition name="zoom">
          <div
            id="dialog_window"
            class="dialog__wrapper"
          >
            <r-title type="title-2">
              {{ content.title }}
            </r-title>
            <r-divider />
            <r-text v-if="content.message">
              {{ content.message }}
            </r-text>
            <div
              class="dialog__controls"
              :class="{ toLeft }"
            >
              <r-button
                :icon="controls.no.icon || ''"
                type="secondary"
                simple
                @click="promiseState = false"
              >
                {{ controls.no.text || 'Нет' }}
              </r-button>
              <r-button
                :icon="controls.yes.icon || ''"
                :type="controls.yes.type || 'primary'"
                :simple="controls.yes.type === 'simple'"
                mini
                @click="promiseState = true"
              >
                {{ controls.yes.text || 'Да' }}
              </r-button>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </portal>
</template>

<script>
import i18n from '@/extensions/i18n'

export default {
  data() {
    return {
      visible: false,
      content: null,
      controls: null,
      promiseInterval: null,
      promiseState: null,
      toLeft: true
    }
  },
  methods: {
    async showDialog(context = {}) {
      document.addEventListener('keydown', this.keyHandler)
      const { title, message, controls } = context

      this.visible = true

      this.content = {
        title: title || i18n.t('are-you-sure'),
        message: message || ''
      }

      this.controls = controls || {
        yes: {
          text: i18n.t('yes')
        },
        no: {
          text: i18n.t('no')
        }
      }

      return new Promise(res => {
        this.promiseInterval = setInterval(() => {
          if (this.promiseState !== null) {
            res(this.promiseState)
            this.resetDialog()
          }
        }, 50)
      })
    },
    overlayHandler(e) {
      if (e.target.id === 'overlay') {
        this.promiseState = false
      }
    },
    keyHandler(evt) {
      if (!this?.visible) return
      const keys = ['Enter', 'Escape']
      if (!keys.includes(evt.code)) return
      evt.preventDefault()

      this.promiseState = evt.code === 'Enter'
    },
    resetDialog() {
      document.removeEventListener('keydown', this.keyHandler)
      this.promiseState = null
      clearInterval(this.promiseInterval)
      this.promiseInterval = null
      this.content = null
      this.controls = null
      this.visible = false
    }
  }
}
</script>

<style lang="scss">
.dialog {
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000050;
    height: 100vh;
    width: 100vw;
    z-index: 10000;
  }

  &__wrapper {
    background-color: $modal_bg;
    padding: 0.75rem;
    position: fixed;
    top: 4rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 250px;
    max-width: 350px;
  }

  &__controls {
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-end;

    &.toLeft {
      align-self: flex-start;
      direction: rtl;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.33s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.33s;
  animation-fill-mode: both;
  animation-name: zoom;
}
.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
</style>
