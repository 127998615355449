export default (beforeEnter, wrapper) => {
  return {
    name: 'forecast',
    path: 'forecast',
    beforeEnter,
    component: wrapper,
    meta: {
      title: 'forecast:title',
      icon: 'tune-component',
      description: 'forecast:desc'
    },
    children: [
      {
        name: 'digital-twin',
        path: 'digital-twin',
        beforeEnter,
        component: () => import('@/modules/sub-modules/modeling-wrapper'),
        redirect: 'digital-twin/dt-selector',
        meta: {
          title: 'digital-twin:title',
          abbr: 'digital-twin:abbr-title',
          icon: 'edit-zone',
          description: 'digital-twin:desc',
          endpoints: ['digital_twin', 'tileserver'],
          projects: ['niipi', 'eputs', 'cpt_spb', 'gbu_bdd_kazan', 'academic']
        },
        children: [
          {
            name: 'dt-selector',
            path: 'dt-selector',
            beforeEnter,
            component: () => import('@/modules/digital-twin/views/dt-selector'),
            meta: {
              title: 'digital-twin:title',
              subtitle: 'digital-twin:select-model',
              abbr: 'digital-twin:abbr-title',
              icon: 'edit-zone',
              description: 'digital-twin:desc',
              isModelSelector: true
            }
          },
          {
            name: 'dt-main',
            path: 'dt-main',
            beforeEnter,
            component: () => import('@/modules/digital-twin/views/dt-main'),
            meta: {
              title: 'digital-twin:title',
              icon: 'edit-zone',
              description: 'digital-twin:desc'
            }
          },
          {
            name: 'pt-editor',
            path: 'pt-editor',
            beforeEnter,
            component: () => import('@/modules/digital-twin/views/pt-editor'),
            meta: {
              subtitle: 'digital-twin:pt-subtitle',
              title: 'digital-twin:title',
              icon: 'edit-zone',
              description: 'digital-twin:desc'
            }
          }
        ]
      },
      {
        name: 'modeling',
        path: 'modeling',
        beforeEnter,
        component: () => import('@/modules/sub-modules/modeling-wrapper'),
        redirect: 'modeling/selector',
        meta: {
          title: 'modeling:title',
          abbr: 'modeling:abbr-title',
          icon: 'calculator',
          description: 'modeling:desc',
          endpoints: ['modeling', 'tileserver'],
          projects: ['niipi', 'eputs', 'cpt_spb', 'gbu_bdd_kazan', 'academic']
        },
        children: [
          {
            name: 'selector',
            path: 'selector',
            beforeEnter,
            component: () =>
              import('@/modules/sub-modules/model-selector/model-selector'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:selector-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc',
              isModelSelector: true
            }
          },
          {
            name: 'model-view',
            path: 'model-view',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/views/model-view/model-view'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:view-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc'
            }
          },
          {
            name: 'modeling-scenario',
            path: 'scenario/:id',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/views/model-view/calculated-scenario'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:view-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc'
            }
          },
          {
            name: 'matrices',
            path: 'matrices',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/views/matrices/matrices'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:matrices-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc'
            }
          },
          {
            name: 'procedures',
            path: 'procedures',
            beforeEnter,
            component: () =>
              import('@/modules/modeling/views/procedures/procedures'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:procedures-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc'
            }
          },
          {
            name: 'book',
            path: 'book',
            beforeEnter,
            component: () => import('@/modules/modeling/views/book/book'),
            meta: {
              title: 'modeling:title',
              subtitle: 'modeling:book-subtitle',
              abbr: 'modeling:abbr-title',
              icon: 'calculator',
              description: 'modeling:desc'
            }
          }
        ]
      }
    ]
  }
}
