import { cloneDeep } from 'HELPERS'

export default {
  state: {
    map: {},
    fields: {
      pvk: null
    },
    activeType: ''
  },
  getters: {},
  mutations: {
    LICENSES_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')
        if (state[subField[0]][subField[1]]) {
          state[subField[0]][subField[1]] = value
        } else {
          const updField = cloneDeep(state[subField[0]])
          updField[subField[1]] = value
          state[subField[0]] = updField
        }
      } else {
        state[field] = value
      }
    },
    LICENSES_CLEAR(state, field) {
      if (field.includes('.')) {
        const subField = field.split('.')
        delete state[subField[0]][subField[1]]
      } else {
        delete state[field]
      }
    },
    LICENSES_ADD(state, [field, value]) {
      state[field].push(value)
    },
    LICENSES_DELETE(state, [field, value]) {
      const index = state[field].indexOf(value)
      state[field].splice(index, 1)
    }
  }
}
