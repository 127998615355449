export default {
  getAdditionalGisLayersByModule: state => module =>
    state.additionalGisLayers[module],
  isRouteAvailable: state => routeName => state.availableRoutes.has(routeName),
  getAdditionalGisLayers: state => state.additionalGisLayers,
  getMapConfigByModule: state => module => {
    return state[module].map
  },
  getCustomBaseLayers: state => state.customBaseLayers,
  getMapPosition: state => state.user_config?.position,
  getProfileVersion: state => state.profileVersion,
  getProfileId: state => state.user?.profile?.id,
  getGeomEditorState: state => state.isDrawing,
  getMapZoom: state => state.user_config?.zoom,
  getDefaultPath: state => state.defaultPath,
  isAuthorized: state => state.authorized,
  getDownloads: state => state.downloads,
  getAbbrState: state => state.abbr,
  getLocale: state => state.locale,
  getTheme: state => state.theme
}
