export default {
  state: {
    city: null,
    map: {}
  },
  mutations: {
    ISOCHRONES_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')

        state[subField[0]][subField[1]] = value
      } else {
        state[field] = value
      }
    }
  }
}
