import COLORS from 'COLORS'

const { blue, white, active, green, orange, routeColor } = COLORS.mapColors

const point = {
  type: 'circle',
  iconInsteadPoint: null,
  style: {
    paint: {
      'circle-radius': 2,
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        active,
        blue
      ],
      'circle-opacity': 1,
      'circle-stroke-width': 1,
      'circle-stroke-color': white,
      'circle-stroke-opacity': 1,
      'circle-pitch-alignment': 'map'
    }
  },
  filters: [],
  heatmap: {
    enabled: false,
    opacity: 0.8,
    radius: 40,
    intensity: 40,
    currentPalette: null,
    weightField: null,
    loading: false,
    loaded: false
  },
  cluster: {
    enabled: false,
    loading: false,
    loaded: false
  }
}

const linestring = {
  type: 'line',
  style: {
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-opacity': 1,
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        active,
        blue
      ],
      'line-width': 2
    }
  },
  epure: {
    enabled: false,
    widthField: '',
    heightField: '',
    widthMultiplier: 50,
    heightMultiplier: 32,
    height: 50,
    extruded: false,
    minHeightValue: 0,
    maxHeightValue: 1,
    minWidthValue: 0,
    maxWidthValue: 1,
    color: active,
    opacity: 0.5
  },
  filters: [],
  arrows: {
    enabled: false
  }
}

const polygon = {
  type: 'fill',
  cluster: {
    enabled: false,
    loading: false,
    loaded: false
  },
  style: {
    paint: {
      'fill-opacity': 0.4,
      // 'fill-color': blue,
      'fill-outline-color': blue,
      'fill-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        active,
        blue
      ]
    }
  },
  matrix: {
    enabled: false,
    field: ''
  },
  centroid: {
    paint: {
      'circle-radius': 2,
      'circle-color': blue,
      'circle-opacity': 1,
      'circle-stroke-width': 2,
      'circle-stroke-color': white,
      'circle-pitch-alignment': 'map'
    }
  },
  strokeStyle: {
    paint: {
      'line-color': blue,
      'line-width': 2,
      'line-opacity': 1
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    }
  },
  extrusion: {
    paint: {
      'fill-extrusion-height': 1500,
      'fill-extrusion-opacity': 0.8,
      'fill-extrusion-vertical-gradient': false
    },
    enabled: false,
    multiplier: 1,
    field: '',
    fieldMin: 0,
    fieldMax: 1
  },
  filters: []
}

export const clusterLayerConfig = {
  type: 'circle',
  filter: ['has', 'point_count'],
  paint: {
    'circle-pitch-alignment': 'map',
    'circle-color': [
      'step',
      ['get', 'point_count'],
      green,
      50,
      orange,
      200,
      routeColor,
      1000,
      active
    ],
    'circle-opacity': 0.8,
    'circle-radius': [
      'step',
      ['get', 'point_count'],
      12,
      50,
      14,
      200,
      16,
      1000,
      24
    ]
  }
}

export const activeObjectsConfig = {
  point: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 3,
      'circle-color': active,
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': white,
      'circle-stroke-width': 1
    }
  },
  line_string: {
    type: 'line',
    layout: {},
    paint: {
      'line-width': 3,
      'line-color': active
    }
  },
  polygon: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': active,
      'fill-opacity': 0.6
    }
  },
  polygon_border: {
    type: 'line',
    layout: {},
    paint: {
      'line-width': 2,
      'line-color': active
    }
  }
}

export const directionsStyleConfig = {
  layout: {
    'symbol-placement': 'line',
    'symbol-spacing': 50,
    'icon-image': 'play',
    'icon-size': 0.25
  },
  paint: {
    'icon-color': blue
  }
}

export const clusterCountLayerConfig = {
  type: 'symbol',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': [
      'case',
      ['>', ['get', 'point_count'], 1000],
      [
        'concat',
        [
          'number-format',
          ['/', ['get', 'point_count'], 1000],
          { 'min-fraction-digits': 0, 'max-fraction-digits': 1 }
        ],
        ' тыс.'
      ],
      ['>', ['get', 'point_count'], 100000],
      [
        'number-format',
        ['/', ['get', 'point_count'], 1000],
        { 'max-fraction-digits': 0.1 }
      ],
      ['get', 'point_count']
    ],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
    'text-allow-overlap': true,
    'text-pitch-alignment': 'map'
  },
  paint: {
    'text-color': white
  }
}

export const styleConfigs = {
  circle: point,
  line: linestring,
  fill: polygon
}
