import COLORS from 'COLORS'

export default {
  state: {
    city: null,
    map: {},
    stopId: null,
    stopProperties: null,
    routeId: null,
    vehicleId: null,
    activeRoutesId: [],
    activeGroupsId: [],
    currentRoute: null,
    currentRoutes: [],
    currentPointCoordinates: [],
    routeColor: COLORS.mapColors.default,
    fields: {},
    boardsId: null,
    routeStopsIds: null,
    vehicleTypes: null,
    layerNames: {},
    heatmap: {
      active: false,
      layer: null,
      attribute: null
    },
    additional_filters: {
      bus_stop: null,
      vehicles: null,
      info_boards: null
    }
  },
  getters: {
    getPTStopId: state => state.stopId,
    getPTRouteId: state => state.routeId,
    getPTStopProperties: state => state.stopProperties,
    getPTactiveRoutesId: state => state.activeRoutesId,
    getPTactiveGroupsId: state => state.activeGroupsId,
    getPTvehicleId: state => state.vehicleId,
    getPTcity: state => state.city,
    getPTcurrentRoutes: state => state.currentRoutes,
    getCurrentPointCoordinates: state => state.currentPointCoordinates,
    getPTrouteColor: state => state.routeColor,
    getPTfields: state => state.fields,
    getPTBoardsId: state => state.boardsId,
    getPTrouteStopsIds: state => state.routeStopsIds,
    getPTvehicleTypes: state => state.vehicleTypes,
    getPTactiveReportId: state => state.activeReportId
  },
  mutations: {
    PUBLIC_TRANSPORT_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')

        state[subField[0]][subField[1]] = value
      } else {
        state[field] = value
      }
    },
    PUBLIC_TRANSPORT_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    PUBLIC_TRANSPORT_SPLICE(state, [field, value]) {
      const index = state[field].indexOf(value)
      if (index >= 0) state[field].splice(index, 1)
    }
  }
}
