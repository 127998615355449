import vue from 'vue'

export default {
  state: {
    map: {},
    city: null,
    violationId: null,
    cameraId: null,
    weightPointId: null,
    fields: null,
    statuses: [],
    heatmap: {
      active: false,
      layer: null,
      attribute: null
    },
    additional_filters: {
      weight: null
    }
  },
  getters: {
    getPDDMapConfig: state => state.map,
    getPDDCity: state => state.city,
    getPDDViolationId: state => state.violationId,
    getPDDCameraId: state => state.cameraId,
    getPDDWeightPointId: state => state.weightPointId,
    getPDDFields: state => state.fields,
    getPDDStatuses: state => state.statuses
  },
  mutations: {
    PDD_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        vue.set(state, `${field}`, value)
      }
    }
  }
}
