// MAIN_TITLE
import en from './en'
import ru from './ru'
import errors from './errors'

// Modules
import pdd from '@/modules/pdd/config/locale'
import gis from '@/modules/gis/config/locale'
import info from '@/modules/info/config/locale'
import user from '@/modules/user/config/locale'
import mrir from '@/modules/mrir/config/locale'
import ksodd from '@/modules/ksodd/config/locale'
import asudd from '@/modules/asudd/config/locale'
import tsodd from '@/modules/tsodd/config/locale'
import reports from '@/modules/reports/config/locale'
import its from '@/modules/its-control/config/locale'
import parking from '@/modules/parking/config/locale'
import modeling from '@/modules/modeling/config/locale'
import scenario from '@/modules/scenario/config/locale'
import licenses from '@/modules/licenses/config/locale'
import explorer from '@/modules/explorer/config/locale'
import emergency from '@/modules/emergency/config/locale'
import roadWorks from '@/modules/road-works/config/locale'
import regulations from '@/modules/regulations/config/locale'
import digitalTwin from '@/modules/digital-twin/config/locale'
import monitoringPt from '@/modules/monitoring-pt/config/locale'
import isochronesAnalysis from '@/modules/isochrone-analysis/config/locale'
import transportSituation from '@/modules/transport-situation/config/locale'

// Components
import drawPanel from '@/components/draw-panel/config/locale'

export default {
  ru: Object.assign(
    ru,
    its.ru,
    gis.ru,
    pdd.ru,
    mrir.ru,
    user.ru,
    info.ru,
    asudd.ru,
    ksodd.ru,
    tsodd.ru,
    errors.ru,
    parking.ru,
    reports.ru,
    modeling.ru,
    explorer.ru,
    licenses.ru,
    scenario.ru,
    emergency.ru,
    drawPanel.ru,
    roadWorks.ru,
    regulations.ru,
    digitalTwin.ru,
    monitoringPt.ru,
    transportSituation.ru,
    isochronesAnalysis.ru
  ),
  en: Object.assign(
    en,
    gis.en,
    pdd.en,
    its.en,
    user.en,
    mrir.en,
    info.en,
    ksodd.en,
    asudd.en,
    tsodd.en,
    errors.en,
    parking.en,
    reports.en,
    modeling.en,
    explorer.en,
    licenses.en,
    scenario.en,
    drawPanel.en,
    roadWorks.en,
    emergency.en,
    digitalTwin.en,
    regulations.en,
    monitoringPt.en,
    isochronesAnalysis.en,
    transportSituation.en
  )
}
