export default {
  state: {
    city: null,
    map: {},
    field: {
      type: 1,
      mode: 2
    },
    lights: [],
    cardFields: []
  },
  getters: {
    getAsuddCity: state => state.city
  },
  mutations: {
    ASUDD_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')
        state[subField[0]][subField[1]] = value
      } else {
        state[field] = value
      }
    },
    ASUDD_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    ASUDD_SPLICE(state, [field, value]) {
      const index = state[field].findIndex(i => i.id === value.id)
      if (index >= 0) state[field].splice(index, 1)
    }
  }
}
