export default (beforeEnter, wrapper) => {
  return {
    name: 'planning',
    path: 'planning',
    beforeEnter,
    component: wrapper,
    meta: {
      title: 'planning:title',
      icon: 'map',
      description: 'planning:desc'
    },
    children: [
      {
        name: 'gis',
        path: 'gis',
        beforeEnter,
        component: () => import('@/modules/gis/gis'),
        meta: {
          title: 'gis:module-title',
          abbr: 'gis:abbr-module-title',
          icon: 'geometry-instruments',
          description: 'gis:module-desc',
          endpoints: ['gis'],
          projects: [
            'gbu_bdd_kazan',
            'rut_miit',
            'cpt_spb',
            'academic',
            'minimal',
            'ryazan',
            'surgut',
            'eputs',
            'niipi',
            'tagil',
            'mmk'
          ]
        }
      },
      {
        name: 'ksodd-podd',
        path: 'ksodd',
        beforeEnter,
        component: wrapper,
        meta: {
          title: 'ksodd:title',
          abbr: 'ksodd:abbr-title',
          icon: 'globe',
          description: 'ksodd:desc',
          endpoints: ['ksodd'],
          projects: ['eputs', 'surgut', 'academic']
        },
        children: [
          {
            name: 'ksodd',
            path: 'ksodd',
            beforeEnter,
            component: () => import('@/modules/ksodd/ksodd'),
            meta: {
              title: 'ksodd:title',
              abbr: 'ksodd:abbr-title',
              icon: 'globe',
              description: 'ksodd:desc'
            }
          },
          {
            name: 'podd',
            path: 'podd',
            beforeEnter,
            component: () => import('@/modules/ksodd/podd/podd'),
            meta: {
              title: 'ksodd:title',
              subtitle: 'ksood:podd-subtitle',
              abbr: 'ksodd:abbr-title',
              icon: 'globe',
              description: 'ksodd:desc'
            }
          }
        ]
      },
      {
        name: 'parking',
        path: 'parking',
        beforeEnter,
        component: wrapper,
        redirect: 'parking/main',
        meta: {
          title: 'parking:title',
          abbr: 'parking:abbr-title',
          icon: 'parking-menu',
          description: 'parking:desc',
          endpoints: ['parking_space'],
          projects: ['eputs']
        },
        children: [
          {
            name: 'parking-main',
            path: 'main',
            beforeEnter,
            component: () => import('@/modules/parking/views/parking'),
            meta: {
              abbr: 'parking:abbr-title',
              title: 'parking:title',
              icon: 'parking-menu',
              description: 'parking:desc'
            }
          },
          {
            name: 'parking-registry',
            path: 'registry',
            beforeEnter,
            component: () => import('@/modules/parking/views/registry'),
            meta: {
              abbr: 'parking:abbr-title',
              title: 'parking:title',
              subtitle: 'parking:registry',
              icon: 'parking-menu'
            }
          }
        ]
      },
      {
        name: 'scenario',
        path: 'scenario',
        beforeEnter,
        component: wrapper,
        redirect: 'scenario/main',
        meta: {
          title: 'scenario:title',
          abbr: 'scenario:abbr-title',
          icon: 'scenario-plan',
          description: 'scenario:desc',
          endpoints: ['scenario_plans'],
          projects: ['eputs']
        },
        children: [
          {
            name: 'scenario-main',
            path: 'main',
            beforeEnter,
            component: () => import('@/modules/scenario/views/scenario'),
            meta: {
              title: 'scenario:title',
              icon: 'scenario-plan',
              abbr: 'scenario:abbr-title',
              description: 'scenario:desc'
            }
          }
        ]
      }
    ]
  }
}
