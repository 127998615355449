import $store from 'STORE'
import { flyToGeom } from '@/helpers/map'

export const setCity = ({ mapgl, city, module }) => {
  const availableCities = $store.state.availableCities
  const defaultCity = $store.state[module].city

  if (availableCities.length) {
    const currentCity = city || defaultCity || availableCities[0].abbr

    const { center, zoom } = availableCities.find(
      item => item.id === currentCity
    )

    if (center && mapgl) {
      flyToGeom({ mapgl, zoom, center, speed: 2 })
    } else {
      console.log('There is no map instance or center coords')
    }

    const commitName = $store.state.storeRelations[module]
    $store.commit(commitName, ['city', currentCity])

    return currentCity
  }
}
