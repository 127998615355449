import { WS_URL, NOTIFY_WS_URL } from '@/config/constants'
import $store from 'STORE'
import eventBus, { eventNames } from 'EVENT_BUS'
const { USER_LOADED, WS_INITED, WS_MESSAGE } = eventNames
const DEV_MODE = process.env?.NODE_ENV === 'development'

// const { WS_URL, NOTIFY_WS_URL } = constants

export default {
  install(Vue) {
    const connectWs = ({ url, name }) => {
      try {
        const ws = new WebSocket(url)

        ws.onopen = e => {
          console.log(`ws ${name} connected`, e)
          eventBus.emit(WS_INITED, ws)
        }

        ws.onmessage = e => {
          if (e?.data) {
            const msg = JSON.parse(e.data)
            eventBus.emit(WS_MESSAGE, msg)
          }
        }

        ws.onerror = e => {
          console.log('ws error', e)
        }

        ws.onclose = e => {
          console.warn('ws connection closed...', e)
        }

        Vue.prototype[`$${name}`] = ws
        Vue[`$${name}`] = ws
      } catch (e) {
        throw new Error(e)
      }
    }

    eventBus.once(USER_LOADED, () => {
      const token = $store.getters.getToken

      connectWs({ url: `${NOTIFY_WS_URL}?token=${token}`, name: 'mws' })
    })
  }
}
