import rDate from 'ritm-date'
import $store from 'STORE'

export const getUserFullName = user => {
  if (!user) return '—'
  let last, first
  const { surname, name, username } = user

  if (surname) {
    last = capitalizeFirst(surname)
  }
  if (name) {
    first = capitalizeFirst(name)
  }

  return last && first
    ? `${last} ${first}`
    : !last && !first
    ? username
    : first || last
}

export const isNumber = n => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const typeIsNumber = type => {
  const types = {
    number: true,
    integer: true,
    numeric: true,
    decimal: true
  }
  return Object.hasOwn(types, type)
}

export const typeIsSimple = type => {
  return (
    typeIsNumber(type) || ['boolean', 'string'].includes(type.toLowerCase())
  )
}

export const dataURLtoBlob = dataurl => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new Blob([u8arr], { type: mime })
}

export const saveBlobAsFile = (blob, name) => {
  const a = document.createElement('a')
  const url = URL.createObjectURL(blob)

  a.href = url
  a.download = name

  document.body.appendChild(a)
  a.click()

  setTimeout(function () {
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }, 0)
}

export const capitalizeFirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getValueByType = (value, type = 'default', k, lvl = 0) => {
  const level = 1 + lvl
  if (level > 2) return '...'

  const isObject = obj => {
    return Object.prototype.toString.call(obj) === '[object Object]'
  }
  if (value === null || value === undefined) {
    return '— '
  } else if (Array.isArray(value)) {
    return value.length > 1
      ? value.map(v => getValueByType(v, type)).join(', ')
      : getValueByType(value[0], type) || '— '
  } else if (isObject(value)) {
    const objectKeys = Object.keys(value)
    if (!objectKeys.length) {
      return '— '
    }
    return objectKeys
      .map(key => {
        if (key === 'geom' || key === 'geometry') {
          return 'geometry object'
        }
        return `${k ? '∟ ' + key : key}: ${
          isObject(value[key]) && !!Object.keys(value[key]).length
            ? '\n' + getValueByType(value[key], type, key, level)
            : getValueByType(value[key], type) + ';'
        }`
      })
      .join('\n')
  } else if (typeof value === 'boolean') {
    // return i18n.t(value ? 'yes' : 'no') // TODO: upgrade to i18n
    return value ? 'Да' : 'Нет'
  } else if (type === 'date') {
    return rDate(value).format('DD.MM.YYYY')
  } else if (type === 'datetime') {
    return rDate(value).format('DD.MM.YYYY HH:mm')
    // TODO: maybe format()
  } else if (type === 'time') {
    if (rDate(value).date == 'Invalid Date') {
      return value.split('+')[0]
    } else {
      rDate(value).format('HH:mm')
    }
  } else {
    return value || isNumber(value) ? String(value) : '—'
  }
}

export const getDegree = number => {
  const digits = {
    '-': '\u{207B}',
    0: '\u{2070}',
    1: '\u{00B9}',
    2: '\u{00B2}',
    3: '\u{00B3}',
    4: '\u{2074}',
    5: '\u{2075}',
    6: '\u{2076}',
    7: '\u{2077}',
    8: '\u{2078}',
    9: '\u{2079}'
  }

  let degree = ''

  for (let n of String(number)) {
    degree += digits[n]
  }

  return degree
}

export const camelToSnakeCase = str =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const isHoverCondition = condition => {
  return (
    condition &&
    Array.isArray(condition) &&
    condition.flat(2).includes('feature-state')
  )
}

export const minMaxNotANull = ({ min, max }) => {
  return Number(min) !== Number(max)
}

export const parseFields = ({ properties, options, module }) => {
  const storageRelations = sessionStorage.getItem(`${module}_relations`)
  const relations = storageRelations ? JSON.parse(storageRelations) : {}
  const locale = $store.getters.getLocale
  const id = options.fieldsType
  const allFields = $store.state[module].fields[id]
  const fields = []

  const getTitle = key => {
    if (!id) return options.titles?.[key] || key
    return typeof allFields[key] === 'object' || !allFields[key]
      ? allFields[key]?.alias?.[locale] || key
      : allFields[key]
  }

  for (const key in properties) {
    if (
      ((options.systemFields && !options.systemFields.includes(key)) ||
        options.displayProperties?.includes(key)) &&
      allFields[key]?.type !== 'geometry' &&
      allFields[key]?.type !== 'json'
    ) {
      let value

      const currRelations = relations[id]
        ? relations[id]?.[key]
        : relations?.[key]

      if (currRelations) {
        const relation = Array.isArray(currRelations)
          ? currRelations.find(x => x.id === properties[key])
          : currRelations[properties[key]]

        const nameField = allFields[key].relation?.alias_key

        value = relation?.alias?.[locale] || relation?.[nameField]
      } else {
        value = properties[key] === 'null' ? null : properties[key]
      }

      if (allFields[key]?.type === 'datetime' && value) {
        value = rDate(value).format('DD.MM.YY HH:mm')
      }

      fields.push({
        title: getTitle(key),
        value
      })
    }
  }

  return fields
}

export const parseToPopup = ({ properties, options, module }) => {
  const fields = parseFields({ properties, options, module })

  const title = `${options.title || ''}${
    options.titleField?.length > 0 ? properties[options.titleField] : ''
  }`

  return {
    id: properties.id,
    icon: options.icon,
    title,
    caption: options.caption
      ? properties[options.caption]
      : `ID: ${properties.id}`,
    fields
  }
}
