import vue from 'vue'

export default {
  state: {
    tree: [],
    map: {},
    layerStyles: {},
    layerNames: {},
    fullDataSet: {},
    heatmap: {
      active: false,
      layer: null,
      attribute: null
    },
    mainGisFields: {},
    layerTypes: {},
    additionalLayers: {
      heatmap: false
    },
    additional_filters: {},
    fields: {},
    selectedModelId: null,
    activeLayers: [],
    isSelectDisabled: false,
    zIndex: {},
    scenarioStatuses: null
    // currentModelId: null
  },
  getters: {
    getModelingMapConfig: state => state.map,
    getModelingActiveLayers: state => state.activeLayers,
    getModelingFiltersById: state => id => state.layerStyles[id]?.filters || [],
    getModelingLayerStyles: state => state.layerStyles,
    getModelingLayersZIndex: state => state.zIndex
  },
  mutations: {
    MODELING_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        vue.set(state, `${field}`, value)
      }
    },
    MODELING_SET_FILTER_PROP(state, pr) {
      const { filterId, action, layerId, prop, value } = pr

      const { filters } = state.layerStyles[layerId]

      const index = filters.findIndex(({ id }) => id === filterId)

      if (action === 'delete') {
        filters.splice(index, 1)
      } else if (!filters[index]) {
        console.warn('filter not found!')
        return
      } else if (prop && value !== undefined) {
        filters[index][prop] = value
      }
    },
    MODELING_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    MODELING_ASSIGN(state, { field, key, value }) {
      state[field] = Object.assign({ ...state[field] }, { [key]: value })
    },
    MODELING_SPLICE(state, [field, value]) {
      const index = state[field].indexOf(value)
      if (index >= 0) state[field].splice(index, 1)
    }
  }
}
