export default (beforeEnter, wrapper) => {
  return {
    // monitoring
    name: 'monitoring',
    path: 'monitoring',
    beforeEnter,
    component: wrapper,
    meta: {
      title: 'monitoring',
      icon: 'camera',
      description: 'monitoring:desc'
    },
    children: [
      {
        name: 'monitoring-pt',
        path: 'monitoring-pt',
        beforeEnter,
        redirect: 'monitoring-pt/main',
        component: wrapper,
        meta: {
          title: 'monitoring-pt:title',
          abbr: 'monitoring-pt:abbr-title',
          icon: 'bus-hollow',
          description: 'monitoring-pt:module-description',
          endpoints: ['public_transport'],
          projects: ['eputs', 'tagil']
        },
        children: [
          {
            name: 'pt-main',
            path: 'main',
            meta: {
              title: 'monitoring-pt:title',
              abbr: 'monitoring-pt:abbr-title',
              icon: 'bus-hollow',
              description: 'monitoring-pt:desc'
            },
            beforeEnter,
            component: () =>
              import('@/modules/monitoring-pt/views/monitoring-pt')
          },
          {
            name: 'pt-dashboard',
            path: 'dashboard',
            redirect: 'monitoring-pt/dashboard/general',
            component: wrapper,
            meta: {
              title: 'monitoring-pt:title',
              abbr: 'monitoring-pt:abbr-title',
              subtitle: 'monitoring-pt:subtitle',
              icon: 's-center',
              description: 'monitoring-pt:desc'
            },
            children: [
              {
                name: 'pt-dashboard-general',
                path: 'general',
                meta: {
                  title: 'monitoring-pt:title',
                  abbr: 'monitoring-pt:abbr-title',
                  subtitle: 'monitoring-pt:subtitle',
                  icon: 'bus-hollow',
                  description: 'monitoring-pt:desc'
                },
                beforeEnter,
                component: () =>
                  import('@/modules/monitoring-pt/views/dashboard/general')
              },
              {
                name: 'pt-dashboard-report',
                path: 'report',
                meta: {
                  title: 'monitoring-pt:title',
                  abbr: 'monitoring-pt:abbr-title',
                  subtitle: 'monitoring-pt:subtitle',
                  icon: 'bus-hollow',
                  description: 'monitoring-pt:desc'
                },
                beforeEnter,
                component: () =>
                  import('@/modules/monitoring-pt/views/dashboard/report')
              }
            ]
          }
        ]
      },
      {
        name: 'its-control',
        path: 'its-control',
        beforeEnter,
        redirect: 'its-control/main',
        component: wrapper,
        meta: {
          title: 'its:title',
          abbr: 'its:abbr-title',
          icon: 's-center',
          description: 'its:desc',
          endpoints: ['efficiency_control'],
          projects: ['eputs', 'surgut', 'mmk', 'rut_miit', 'tagil']
        },
        children: [
          {
            name: 'its-main',
            path: 'main',
            meta: {
              title: 'its:title',
              abbr: 'its:abbr-title',
              icon: 's-center',
              description: 'its:desc'
            },
            beforeEnter,
            component: () => import('@/modules/its-control/views/main')
          },
          {
            name: 'its-dashboard',
            path: 'dashboard',
            meta: {
              title: 'its:title',
              abbr: 'its:abbr-title',
              subtitle: 'its:dashboard_title',
              icon: 's-center',
              description: 'its:desc'
            },
            beforeEnter,
            component: () => import('@/modules/its-control/views/dashboard')
          },
          {
            name: 'its-development',
            path: 'development',
            meta: {
              title: 'its:title',
              abbr: 'its:abbr-title',
              subtitle: 'its:development',
              icon: 's-center',
              description: 'its:desc',
              projects: ['eputs']
            },
            beforeEnter,
            component: () => import('@/modules/its-control/views/development')
          },
          {
            name: 'its-notifications',
            path: 'notifications',
            meta: {
              title: 'its:title',
              abbr: 'its:abbr-title',
              subtitle: 'its:notifications',
              icon: 's-center',
              description: 'its:desc',
              projects: ['eputs']
            },
            beforeEnter,
            component: () => import('@/modules/its-control/views/notifications')
          },
          {
            name: 'its-editor',
            path: 'editor',
            meta: {
              title: 'its:title',
              abbr: 'its:abbr-title',
              subtitle: 'its-editor',
              icon: 's-center',
              description: 'its:desc',
              projects: ['eputs']
            },
            beforeEnter,
            component: () => import('@/modules/its-control/views/editor')
          }
        ]
      },
      {
        name: 'pdd',
        path: 'pdd',
        beforeEnter,
        component: wrapper,
        redirect: 'pdd/main',
        meta: {
          title: 'pdd:title',
          abbr: 'pdd:abbr-title',
          icon: 'camera',
          description: 'pdd:desc',
          endpoints: ['traffic_violations'],
          projects: ['eputs']
        },
        children: [
          {
            name: 'pdd-main',
            path: 'main',
            beforeEnter,
            component: () => import('@/modules/pdd/views/pdd'),
            meta: {
              abbr: 'pdd:abbr-title',
              title: 'pdd:title',
              icon: 'camera',
              description: 'pdd:desc'
            }
          },
          {
            name: 'pdd-registry',
            path: 'registry',
            beforeEnter,
            component: () => import('@/modules/pdd/views/registry'),
            meta: {
              title: 'pdd:registry-title',
              description: 'pdd:registry-desc'
            }
          }
        ]
      },
      {
        name: 'licenses',
        path: 'licenses',
        beforeEnter,
        component: () => import('@/modules/licenses/licenses'),
        meta: {
          title: 'permissions:title',
          abbr: 'permissions:abbr-title',
          icon: 'safety-driving',
          description: 'permissions:desc',
          endpoints: ['permissions'],
          projects: ['eputs']
        }
      }
    ]
  }
}
