import COLORS from 'COLORS'

export default {
  state: {
    city: null,
    map: {},
    filters: {
      orders: [],
      vehicles: [],
      quarterlyApps: [],
      quarterlyWorkers: []
    },
    additional_filters: {
      orders: null,
      vehicles: null
    },
    tables: {},
    heatmap: {
      active: false,
      layer: null,
      attribute: null
    },
    fields: {
      quarterlyOrders: null
    },
    services: {
      statuses: null,
      vehicle_types: null,
      quarterly_form_rules: null
    },

    // Router
    routerCard: null,

    // Orders
    orderFields: null,
    orderCardId: null,
    form_rules: null,

    // Vehicles
    showVehicles: false,
    vehiclesSidebar: false,
    vehicleCardId: null,

    // Quarterly
    showQuarterly: false,
    quarterlySidebar: false,
    quarterlyAppEditorId: null,
    quarterlyWorkerCardId: null,
    quarterlyWorkerTrack: null,

    // Layer names for popup list
    layerNames: {},

    // Geofences
    geofenceEditorCardId: null,

    // While load data is tree show loader
    loadingTreeNodeId: null,
    // For check changes when close editor
    hasObjectChanges: false
  },
  getters: {
    getRoadWorkMapConfig: state => state.map,
    getRoadWorkSelectedCity: state => state.city,
    getRoadWorkStatusColorById: state => id =>
      state.services.statuses?.[id]?.color || COLORS.root.white,
    getRoadWorksFields: state => key => state.fields[key]
  },
  mutations: {
    ROAD_WORKS_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')

        state[subField[0]][subField[1]] = value
      } else {
        state[field] = value
      }
    }
  }
}
