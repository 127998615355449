export default (beforeEnter, wrapper) => {
  return {
    name: 'system',
    path: 'system',
    beforeEnter,
    component: wrapper,
    meta: {
      title: 'system',
      icon: 'settings',
      description: 'system:desc'
    },
    children: [
      {
        name: 'mrir',
        path: 'mrir',
        beforeEnter,
        component: () => import('@/modules/mrir/mrir'),
        meta: {
          title: 'mrir:title',
          admin_only: true,
          abbr: 'mrir:abbr-title',
          icon: 'users',
          description: 'mrir:desc'
        }
      },
      {
        name: 'system-monitoring',
        path: 'system-monitoring',
        meta: {
          abbr: 'system-monitoring:abbr-title',
          subtitle: 'system-monitoring:title',
          icon: 'chart-line',
          projects: ['eputs']
        },
        beforeEnter,
        component: () => import('@/modules/system-monitoring')
      },
      {
        name: 'regulations',
        path: 'regulations',
        meta: {
          abbr: 'regulations:abbr-title',
          subtitle: 'regulations:title',
          icon: 'acts-document',
          projects: ['eputs'],
          endpoints: ['road_works', 'permissions']
        },
        beforeEnter,
        component: () => import('@/modules/regulations/regulations')
      },
      {
        name: 'transport-situation',
        path: 'transport-situation',
        meta: {
          // abbr: 'transport-situation:abbr-title',
          subtitle: 'transport-situation',
          icon: 'sort-unselected',
          projects: ['eputs'],
          endpoints: ['transport_situation']
        },
        beforeEnter,
        component: () => import('@/modules/transport-situation/views')
      },
      {
        name: 'isochrone-analysis',
        path: 'isochrone-analysis',
        meta: {
          subtitle: 'isochrone-analysis',
          icon: 'node-layer',
          projects: ['eputs']
        },
        beforeEnter,
        component: () => import('@/modules/isochrone-analysis/views')
      },
      {
        name: 'tsodd',
        path: 'tsodd',
        beforeEnter,
        component: wrapper,
        meta: {
          title: 'tsodd',
          icon: 'speed-limit',
          projects: ['eputs'],
          endpoints: ['road_consulting']
        },
        redirect: 'tsodd/main',
        children: [
          {
            name: 'tsodd-main',
            path: 'main',
            beforeEnter,
            component: () => import('@/modules/tsodd/views/tsodd'),
            meta: { subtitle: 'tsodd', icon: 'speed-limit' }
          },
          {
            name: 'tsodd-registry',
            path: 'registry',
            beforeEnter,
            component: () => import('@/modules/tsodd/views/registry'),
            meta: {
              title: 'tsodd',
              subtitle: 'tsodd:registry_title',
              icon: 'speed-limit'
            }
          }
        ]
      },
      {
        name: 'account',
        path: 'account',
        beforeEnter,
        component: () => import('@/modules/mrir/account'),
        meta: {
          title: 'account:title',
          abbr: 'account:abbr-title',
          icon: 'user'
        }
      },
      {
        name: 'profile',
        path: 'profile',
        beforeEnter,
        component: () => import('@/modules/user/profile/profile'),
        meta: {
          title: 'user:profile_title',
          abbr: 'user:abbr-profile_title',
          icon: 'tasks'
        }
      },
      {
        name: 'activity-log',
        path: 'activity-log',
        beforeEnter,
        component: () => import('@/modules/user/activity-log/activity-log'),
        meta: {
          admin_only: true,
          title: 'user:activity-log',
          abbr: 'user:activity-log',
          icon: 'ksodd-tables',
          projects: []
        }
      },
      {
        name: 'ui',
        path: 'ui',
        beforeEnter,
        component: () => import('@/modules/ui-kit/ui-kit'),
        meta: {
          dev_only: true,
          title: 'UI компоненты',
          abbr: 'UI',
          icon: 'style-presets',
          projects: 'dev'
        }
      },
      {
        name: 'docs',
        path: 'docs',
        beforeEnter() {
          location.href = 'https://sphinx.ritm3.ru/' // Might be a problem in vue3
        },
        meta: {
          title: 'docs:title',
          abbr: 'docs:abbr-title',
          icon: 'acts-document',
          projects: ['eputs']
        }
      },
      {
        name: 'reports',
        path: 'reports',
        beforeEnter,
        component: () => import('@/modules/reports/reports'),
        meta: {
          admin_only: true,
          title: 'reports:title',
          abbr: 'reports:title',
          icon: 'charts-general',
          projects: ['eputs']
        }
      }
    ]
  }
}
