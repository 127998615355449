import vue from 'vue'

export default {
  state: {
    city: null,
    fields: {},
    mainModalVisibility: false,
    groupModalVisibility: false
  },
  getters: {},
  mutations: {
    SCENARIO_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        vue.set(state, `${field}`, value)
      }
    }
  }
}
