export default {
  SYSTEM_PUSH(state, [field, value]) {
    state[field].push(value)
  },
  SYSTEM_SPLICE(state, [field, value]) {
    const index = state[field].indexOf(value)
    if (index >= 0) state[field].splice(index, 1)
  },
  SYSTEM_SET(state, [field, value]) {
    if (field.includes('.')) {
      const subField = field.split('.')
      state[subField[0]][subField[1]] = value
    } else {
      state[field] = value
    }
  },
  SYSTEM_ASSIGN(state, { field, key, value }) {
    if (value) {
      state[field] = Object.assign({ ...state[field] }, { [key]: value })
    } else {
      const obj = Object.assign({}, state[field])
      delete obj[key]
      state[field] = obj
    }
  }
}
