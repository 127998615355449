<template>
  <div class="r-input-time">
    <r-text v-if="label">
      {{ label }}
    </r-text>
    <div
      class="r-input-time__body"
      :class="{ error: error || notValid, disabled }"
    >
      <r-icon
        v-if="icon"
        class="r-input-time__body-icon"
        name="in-process"
      />
      <div class="r-input-time__body-input-left">
        <input
          ref="hoursRef"
          class="r-input-time__body-input"
          :value="valueHours"
          :placeholder="placeholder ? $t('hh') : ''"
          :disabled="disabled"
          @input="inputHours"
          @keydown.backspace="handleBackspace"
          @keydown.left="handleLeft"
          @keydown.right="handleRight"
          @blur="blur"
        />
      </div>
      <input
        ref="minutesRef"
        class="r-input-time__body-input"
        :value="valueMinutes"
        :placeholder="placeholder ? $t('mm') : ''"
        :disabled="disabled"
        @input="inputMinutes"
        @keydown.backspace="handleBackspace"
        @keydown.left="handleLeft"
        @keydown.right="handleRight"
        @blur="blur"
      />
    </div>
  </div>
</template>

<script setup>
import { isNumber } from '@/helpers'
import { computed, ref } from 'vue'
// eslint-disable-next-line no-undef
const emit = defineEmits(['input'])

// eslint-disable-next-line no-undef
const props = defineProps({
  value: {
    type: [String, Number],
    default: '00:00'
  },
  placeholder: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  icon: {
    type: Boolean,
    default: false
  },
  min: {
    type: String,
    default: null
  },
  max: {
    type: String,
    default: null
  }
})

const hoursRef = ref(null)
const minutesRef = ref(null)
const notValid = ref(false)

const valueHours = computed(() => {
  const arr = props.value?.split(':')
  if (arr?.length > 1) return arr[0]
  return props.value
})

const valueMinutes = computed(() => {
  const arr = props.value?.split(':')
  if (arr?.length > 1) return arr[1]
  return null
})

const getMinutes = str => {
  let minutes = str.slice(0, 2)
  if (minutes > 59) {
    minutes = str.slice(0, 1)
  }

  return minutes
}

const getFullTime = () => {
  if (!hoursRef.value.value && !minutesRef.value.value) return null
  return hoursRef.value.value + ':' + minutesRef.value.value
}

const format = (hh, mm) => {
  const str = mm === null ? hh : hh + mm

  let hours = str.slice(0, 2)
  let index = 2

  if (hours > 23) {
    hours = str.slice(0, 1)
    index = 1
  }

  const minutes = getMinutes(str.slice(index))

  return [hours, minutes]
}

const check = () => {
  const arrMin = props.min?.split(':')
  const arrMax = props.max?.split(':')
  let error = false

  if (
    (!props.min && !props.max) ||
    hoursRef.value?.value.length < 2 ||
    minutesRef.value?.value.length < 2 ||
    (arrMin?.length < 2 && arrMax?.length < 2)
  ) {
    notValid.value = false
    return
  }

  if (arrMin?.length === 2) {
    const minH = arrMin[0]
    const minM = arrMin[1]
    error =
      hoursRef.value?.value < minH ||
      (hoursRef.value?.value === minH && minutesRef.value?.value < minM)
  }

  if (!error && arrMax?.length === 2) {
    const maxH = arrMax[0]
    const maxM = arrMax[1]
    error =
      hoursRef.value?.value > maxH ||
      (hoursRef.value?.value === maxH && minutesRef.value?.value < maxM)
  }

  notValid.value = error
}

const inputHours = event => {
  let value = event.target.value.replace(/[^0-9]/g, '')

  if (value.length > 2 || value > 23) {
    const [hours, minutes] = format(value, minutesRef.value.value)
    hoursRef.value.value = hours
    minutesRef.value.value = minutes

    if (minutes.length > 0) minutesRef.value.focus()
    setTimeout(() => {
      minutesRef.value.selectionStart = 1
      minutesRef.value.selectionEnd = 1
    }, 0)
  } else hoursRef.value.value = value

  emit('input', getFullTime())
  check()
}

const inputMinutes = event => {
  const end = minutesRef.value.selectionEnd
  minutesRef.value.value = getMinutes(event.target.value.replace(/[^0-9]/g, ''))

  if (end <= 1) {
    minutesRef.value.selectionStart = 1
    minutesRef.value.selectionEnd = 1
  }

  emit('input', getFullTime())
  check()
}

const handleBackspace = e => {
  if (e.target === minutesRef.value && e.target.selectionStart < 1) {
    hoursRef.value.focus()
  }
}

const handleLeft = e => {
  if (e.target === minutesRef.value && e.target.selectionStart < 1) {
    hoursRef.value.focus()
    setTimeout(() => {
      hoursRef.value.selectionStart += 1
    }, 0)
  }
}

const handleRight = e => {
  if (
    e.target === hoursRef.value &&
    (!hoursRef.value?.value ||
      e.target.selectionStart > hoursRef.value.value.length - 1)
  ) {
    minutesRef.value.focus()
    setTimeout(() => {
      minutesRef.value.selectionEnd -= 1
    }, 0)
  }
}

const blur = e => {
  let hours = hoursRef.value.value
  let minutes = minutesRef.value.value

  if (
    (hours.length > 0 || minutes.length > 0) &&
    e.relatedTarget !== hoursRef.value &&
    e.relatedTarget !== minutesRef.value
  ) {
    hours = hours.padStart(2, '0')
    minutes = minutes.padStart(2, '0')

    hoursRef.value.value = hours
    minutesRef.value.value = minutes
    emit('input', getFullTime())
  }

  check()
}
</script>

<style lang="scss" scoped>
.r-input-time {
  @include grid-column(0.25rem);

  &__body {
    height: 36px;
    display: flex;
    justify-content: center;
    background: $field-bg;
    padding: 0 0.5rem;
    border: 1px solid $field-border;
    border-radius: $border-radius;
    transition: 0.16s;

    &-input {
      width: 30px;
      height: 100%;
      background: transparent;
      border: none;
      color: $text-primary;
      text-align: center;

      &::placeholder {
        color: $field-placeholder;
      }

      &:disabled {
        cursor: not-allowed;
      }

      &-left {
        position: relative;
        &::after {
          content: ':';
          position: absolute;
          right: -2px;
          top: 6px;
          color: $text-primary;
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
    &.error {
      border-color: $accent-danger;
    }
  }
}
</style>
