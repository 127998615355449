export default {
  locale: 'ru',
  theme: null,
  defaultPath: '/app/explorer',
  isFullscreen: false,
  mapLoadingIndicaor: false,
  authorized: false,
  activeRequests: [],
  downloads: {},
  profileVersion: 7, // Is user have to recreate profile profileVersion++ // Int
  abbr: false,
  env: {},
  availableCities: [],
  availableRoutes: new Set(),
  defaultMapConfig: {
    baseLayer: 'dark',
    center: {
      lat: 61.25,
      lng: 73.397
    },
    zoom: 11,
    bearing: 0,
    pitch: 0
  },
  notification: null,
  storeRelations: {
    gis: 'GIS_SET',
    pdd: 'PDD_SET',
    info: 'INFO_SET',
    ksodd: 'KSODD_SET',
    asudd: 'ASUDD_SET',
    parking: 'PARKING_SET',
    licenses: 'LICENSES_SET',
    modeling: 'MODELING_SET',
    scenario: 'SCENARIO_SET',
    emergency: 'EMERGENCY_SET',
    roadWorks: 'ROAD_WORKS_SET',
    itsControl: 'ITS_CONTROL_SET',
    digitalTwin: 'DIGITAL_TWIN_SET',
    digital_twin: 'DIGITAL_TWIN_SET',
    permissions: 'PERMIT_REGISTER_SET',
    tsodd: 'TSODD_SET',
    efficiency_control: 'ITS_CONTROL_SET',
    publicTransport: 'PUBLIC_TRANSPORT_SET',
    public_transport: 'PUBLIC_TRANSPORT_SET',
    transportSituation: 'TRANSPORT_SITUATION_SET',
    isochronesAnalysis: 'ISOCHRONES_SET'
  },
  additionalGisLayers: {},
  isDrawing: false,
  customBaseLayers: []
}
